// Native
import {Injectable} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class ValidateService {

  propertyEmitter: string = 'validateEmitter';

  getErrorMessage = {
    parent: this,
    unknow(): string { // this have to be argument for latin and Cyrillic
      return 'unknow';
    },
    required(): string { // this have to be argument for latin and Cyrillic
      return 'required';
    },
    incorrectTime(): string { // this have to be argument for latin and Cyrillic
      return 'incorrectTime';
    },
    pattern(regexp): string { // this have to be argument for latin and Cyrillic
      let answer: any;
      this.parent.patternsDone.forEach((item) => {
        if (item.regexp === regexp) {
          answer = '';
        }
      });
      return answer;
    },
    minlength(value): string {
      return 'minlength';
    },
    maxlength(value): string {
      return 'maxlength';
    },
    min(value): string {
      return 'min';
    },
    max(value): string {
      return 'max';
    },
    confirmPassword(): string {
      return 'confirmPassword';
    },
    password(): string {
      return 'password';
    }
  };

  patterns: any[] = [
    this.validateStringCyrillic(),
    this.validateStringLatin(),
    this.validateString(),
    this.validateStringWithoutNumbersCyrillic(),
    this.validateStringWithoutNumbersLatin(),
    this.validateStringWithoutNumbers(),
    this.validateStringCyrillicStrict(),
    this.validateStringLatinStrict(),
    this.validateStringStrict(),
    this.validateStringWithoutNumbersCyrillicStrict(),
    this.validateStringWithoutNumbersLatinStrict(),
    this.validateStringWithoutNumbersStrict(),
    this.validateWordCyrillic(),
    this.validateWordLatin(),
    this.validateWord(),
    this.validateDoubleWordCyrillic(),
    this.validateDoubleWordLatin(),
    this.validateDoubleWord(),
    this.validateTrippleWordCyrillic(),
    this.validateTrippleWordLatin(),
    this.validateTrippleWord(),
    this.validateFIO(),
    this.validateNumber(),
    this.validateNonNegativeNumber(),
    this.validateNumberWithDots(),
    this.validateDate(),
    this.validatePhone(),
    this.validateEmail(),
    this.validateUrl(),
    this.validateTime()
  ];

  patternsDone = this.patterns.map((item: any) => {
    item.regexp = '/' + item.regex + '/';
    return item;
  });

  constructor() {}

  validate(form: FormGroup | FormControl | FormArray): boolean {
    if (form[this.propertyEmitter]) {
      form[this.propertyEmitter].emit();
    } else {
      console.warn('This FormGroup, FormArray or FormControl not use ValidateDirective');
    }

    return form.valid;
  }

  setError(control: AbstractControl, error: any) {
    control.setErrors(Object.assign({}, control.errors, error));
  }

  removeError(control: AbstractControl, error: string) {
    const err = control.errors; // get control errors
    if (err) {
      delete err[error]; // delete your own error
      if (!Object.keys(err).length) // if no errors left
        control.setErrors(null); // set control errors to null making it VALID
      else
        control.setErrors(err); // controls got other errors so set them back
    }
  }


  /* Карта описаний регулярных выражений:
  *
  * (рус), (англ), (рус/англ)... - языки, на которых вводятся строки или слова
  * Слово....................... - набор символов, не разделённых пробелом
  * Строка...................... - набор символов, разделённых пробелом
  * ->.......................... - следующие входные данные в рамках одного выражения
  * (необязательно)............. - данные, описанные далее, вводить необязательно
  *
  * */


  validateStringCyrillic() {
    return {

      /*    (рус)
            -> Строка с пробелами, цифрами и спецсимволами*/

      regex: '^[а-яА-ЯёЁ\\d\\W]+$', // old: '^([а-яА-ЯёЁ\d]+){0,}',
      key: 'enterStringRus',
    };
  }

  validateStringLatin() {
    return {

      /*    (англ)
            -> Строка с пробелами, цифрами и спецсимволами*/

      regex: '^[a-zA-Z\\d\\W]+$',
      key: 'enterStringEng',
    };
  }

  validateString() {
    return {

      /*    (рус/англ)
            -> Строка с пробелами, цифрами и спецсимволами*/

      regex: '^[а-яА-ЯёЁa-zA-Z\\d\\W]+$',
      key: 'enterString',
    };
  }

  validateStringWithoutNumbersCyrillic() {
    return {

      /*    (рус)
            -> Строка с пробелами и спецсимволами, но без цифр */

      regex: '^[а-яА-ЯёЁ\\W]+$',
      key: 'enterStringWithoutNumbersRus',
    };
  }

  validateStringWithoutNumbersLatin() {
    return {

      /*    (англ)
            -> Строка с пробелами и спецсимволами, но без цифр */

      regex: '^[a-zA-Z\\W]+$',
      key: 'enterStringWithoutNumbersEng',
    };
  }

  validateStringWithoutNumbers() {
    return {

      /*    (рус/англ)
            -> Строка с пробелами и спецсимволами, но без цифр */

      regex: '^[а-яА-ЯёЁa-zA-Z\\W]+$',
      key: 'enterStringWithoutNumbers',
    };
  }

  validateStringCyrillicStrict() {
    return {

      /*    (рус)
            -> Строка с цифрами, но без спецсимволов */

      regex: '^[а-яА-ЯёЁ\\d\\s]+$',
      key: 'enterStringWithoutSpecialRus',
    };
  }

  validateStringLatinStrict() {
    return {

      /*    (англ)
            -> Строка с цифрами, но без спецсимволов */

      regex: '^[a-zA-Z\\d\\s]+$',
      key: 'enterStringWithoutSpecialEng',
    };
  }

  validateStringStrict() {
    return {

      /*    (рус/англ)
            -> Строка с цифрами, но без спецсимволов */

      regex: '^[а-яА-ЯёЁa-zA-Z\\d\\s]+$',
      key: 'enterStringWithoutSpecial',
    };
  }

  validateStringWithoutNumbersCyrillicStrict() {
    return {

      /*    (рус)
            -> Строка без чисел и спецсимволов */

      regex: '^[а-яА-ЯёЁ\\s]+$',
      key: 'enterStringWithoutNumbersAndSpecialRus',
    };
  }

  validateStringWithoutNumbersLatinStrict() {
    return {

      /*    (англ)
            -> Строка без чисел и спецсимволов */

      regex: '^[a-zA-Z\\s]+$',
      key: 'enterStringWithoutNumbersAndSpecialEng',
    };
  }


  validateTime() {
    return {
      regex: '^(([0,1][0-9])|(2[0-3])):[0-5][0-9]$',
      key: 'enterTimeHHmm',
    };
  }

  validateStringWithoutNumbersStrict() {
    return {

      /*    (рус/англ)
            -> Строка без чисел и спецсимволов */

      regex: '^[а-яА-ЯёЁa-zA-Z\\s]+$',
      key: 'enterStringWithoutNumbersAndSpecial',
    };
  }

  validateWordCyrillic() {
    return {

      /*    (рус)
            -> Слово без чисел и спецсимволов */

      regex: '^[а-яА-ЯёЁ]+$',
      key: 'enterOneWordRus',
    };
  }

  validateWordLatin() {
    return {

      /*    (англ)
            -> Слово без чисел и спецсимволов */

      regex: '^[a-zA-Z]+$',
      key: 'enterOneWordEng',
    };
  }

  validateWord() {
    return {

      /*    (рус/англ)
            -> Слово без чисел и спецсимволов */

      regex: '^[а-яА-ЯёЁa-zA-Z]+$',
      key: 'enterOneWord',
    };
  }

  validateDoubleWordCyrillic() {

    /*    (рус)
          -> Слово
          -> пробел
          -> слово */

    return {
      regex: '^[а-яА-ЯёЁ]+\\s[а-яА-ЯёЁ]+$',
      key: 'enterTwoWordRus',
    };
  }

  validateDoubleWordLatin() {
    return {

      /*    (англ)
            -> Слово
            -> пробел
            -> слово */

      regex: '^[a-zA-Z]+\\s[a-zA-Z]+$',
      key: 'enterTwoWordEng',
    };
  }

  validateDoubleWord() {

    /*    (рус/англ)
          -> Слово
          -> пробел
          -> слово */

    return {
      regex: '^[а-яА-ЯёЁa-zA-Z]+\\s[а-яА-ЯёЁa-zA-Z]+$',
      key: 'enterTwoWord',
    };
  }

  validateTrippleWordCyrillic() {
    return {

      /*    (рус)
            -> Слово
            -> пробел
            -> слово
            -> пробел
            -> слово  */

      regex: '^[а-яА-ЯёЁ+(\\s[а-яА-ЯёЁ]+){2}$',
      key: 'enterThreeWordRus',
    };
  }

  validateTrippleWordLatin() {
    return {

      /*    (англ)
            -> Слово
            -> пробел
            -> слово
            -> пробел
            -> слово  */

      regex: '^[a-zA-Z]+(\\s[a-zA-Z]+){2}$',
      key: 'enterThreeWordEng',
    };
  }

  validateTrippleWord() {
    return {

      /*    (рус/англ)
            -> Слово
            -> пробел
            -> слово
            -> пробел
            -> слово  */

      regex: '^[а-яА-ЯёЁa-zA-Z]+(\\s[а-яА-ЯёЁa-zA-Z]+){2}$',
      key: 'enterThreeWord',
    };
  }

  validateFIO() {
    return {

      // Фамилия и имя могут быть в 2 слова через дефис

      regex: '^[А-Я][а-яА-Я\\-]{0,}\\s[А-Я][а-яА-Я\\-]{1,}\\s[А-Я][а-яА-Я]{1,}$',
      key: 'enterFullName',
    };
  }


  validateNumber() {
    return {

      // Число без пробелов, букв и спецсимволов

      regex: '^\\-?\\d+$',
      key: 'enterNumber',
    };
  }

  validateNonNegativeNumber() {
    return {

      // Неотрицательное число без пробелов, букв и спецсимволов

      regex: '^\\d+$',
      key: 'enterNonNegativeNumber',
    };
  }



  validateNumberWithDots() {
    return {

      // Число, после которого (необязательно) идут 2 трёхзначных числа, разделённых точкой

      regex: '^\\d{1,3}(\\.\\d{1,3}){0,2}$',
      key: 'enterNumbersSeparatedByDots',
    };
  }

  validateDate() {
    return {

      // Не проходит проверка на 31.02 - CHANGE

      regex: '^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\\d\\d$',
      key: 'enterDateFormat',
    };
  }

  validatePhone() {
    return {

      /*    -> (обязательно) префикс
            -> код через (необязательно) дефис или в кавычках от 3-х до 5-ти символов
            -> 7 цифр или 7 цифр, между которыми два дефиса*/

      regex: '^((8|\\+7)?)((\\-|\\()?\\d{3,5}(\\-|\\))?)?([\\d]{7}|[\\d\\- ]{9})$',
      key: 'enterPhone',
    };
  }

  validateEmail() {
    return {

      /*    -> Буквы, цифры, _ или дефис
            -> (необязательно) точка
            -> имя сервера
            -> точка
            -> домен от 2-х до 4-х символов*/

      regex: '^([a-zA-Z0-9_-]+\\.)*[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)*\\.[a-zA-Z]{2,20}$',
      key: 'enterEmail',
    };
  }

  validateUrl() {
    return {

      /*    -> (необязательно) https или http, двоеточие и 2 слеша
            -> буквы и цифры (необязательно) разделённые точкой или (необязательно) дефисом
            -> точка
            -> домен от 2-х до 6-ти символов
            -> (необязательно) слеш*/

      regex: '^((https?|http)\\:\\/\\/)?([a-z0-9]{1})((\\.[a-z0-9-])|([a-z0-9-]))*\\.([a-z]{2,6})(\\/?)$',
      key: 'enterURL',
    };
  }
}
