import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {LogoModule} from './components/logo/logo.module';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxPopperModule} from 'ngx-popper';
import { ToastrModule } from 'ngx-toastr';
import {PreloaderModule} from './components/preloader/preloader.module';
import {HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import { ConfirmedEmailComponent } from './pages/confirmed-email/confirmed-email.component';
import { UpdateEmailComponent } from './pages/update-email/update-email.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ConfirmEmailComponent } from './pages/confirm-email/confirm-email.component';
import { ClientHandlerComponent } from './components/client-handler/client-handler.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { HappenedComponent } from './pages/happened/happened.component';
import { UpdatePasswordComponent } from './pages/update-password/update-password.component';
import { ErrorComponent } from './pages/error/error.component';

@NgModule({
  declarations: [
    AppComponent,
    ConfirmedEmailComponent,
    UpdateEmailComponent,
    ResetPasswordComponent,
    ConfirmEmailComponent,
    ClientHandlerComponent,
    HappenedComponent,
    UpdatePasswordComponent,
    ErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LogoModule,
    ReactiveFormsModule,
    NgxPopperModule.forRoot(),
    ToastrModule.forRoot(),
    PreloaderModule,
    RouterModule.forRoot([
      {
        path: 'confirmed-email',
        component: ConfirmedEmailComponent
      },
      {
        path: 'update-email',
        component: UpdateEmailComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'update-password',
        component: UpdatePasswordComponent
      },
      {
        path: 'confirm-email',
        component: ConfirmEmailComponent
      },
      {
        path: 'happened',
        component: HappenedComponent
      },
      {
        path: 'error',
        component: ErrorComponent
      }
    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
