// Native
import { Component, ElementRef, Input, OnChanges, SimpleChange, ViewChild } from '@angular/core';

@Component({
  selector: 'preloader',
  templateUrl: './preloader.component.html'
})
export class PreloaderComponent implements OnChanges {

  @ViewChild('loader', {static: false}) private _loaderEl: ElementRef;

  @Input() select: boolean = false;
  @Input() state: string = '';
  @Input() class: string = '';

  @Input() color: string = 'primary';
  @Input() sm: boolean = false;

  hidden: boolean = false;

  constructor() {}

  ngOnChanges(changes: { [state: string]: SimpleChange }) {
    for (const propName in changes)
      if (changes.hasOwnProperty(propName)) {
        if (propName !== 'state') return;

        this.state === 'fade'
          ? this.hidePreloader()
          : this.hidden = false;
      }
  }

  hidePreloader() {
    if (!this._loaderEl) {
      this.hidden = true;
      return;
    }

    const el = this._loaderEl.nativeElement;

    el.addEventListener('transitionend', () => {
      this.hidden = true;
    }, {once: true});
  }

}
