import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {finalize, takeUntil} from 'rxjs/operators';
import {ValidateService} from '../../validate/validate.service';
import {AuthService} from '../../services/auth.service';
import {NotifyService} from '../../services/notify.service';
import {Subject} from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  form: FormGroup;
  submitEnabled = true;
  params: any;

  unSubscriber: Subject<unknown> = new Subject<unknown>();

  constructor(private authService: AuthService,
              private notifyService: NotifyService,
              private router: Router,
              private route: ActivatedRoute,
              private validateService: ValidateService) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy() {
    this.unSubscriber.next();
    this.unSubscriber.complete();
  }

  initForm() {
    this.form = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6),
        // Validators.maxLength(20)
      ]),
    });
  }

  onSubmit(form) {
    if (this.validateService.validate(form)) {
      form.disable();
      this.submitEnabled = false;

      this.authService.updatePassword({
        password: form.value.password,
        email: this.params.email,
        hash: this.params.hash
      }).pipe(
          takeUntil(this.unSubscriber),
          finalize(() => {
            form.enable();
            this.submitEnabled = true;
          })
        ).subscribe(() => {
        this.router.navigate(['/', 'happened'])
      }, (err) => {

        this.router.navigate(['/', 'error'])
      });
    } else {
      this.notifyService.incorrectData();
    }
  }

}
