import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'client-handler',
  templateUrl: './client-handler.component.html'
})
export class ClientHandlerComponent implements OnInit, OnDestroy {

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    this.renderer.setStyle(document.body, 'background-color', 'white');
  }

  ngOnDestroy() {
    this.renderer.removeStyle(document.body, 'background-color');
  }

}
