import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-confirmed-email',
  templateUrl: './confirmed-email.component.html'
})
export class ConfirmedEmailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
