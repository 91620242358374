import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-happened',
  templateUrl: './happened.component.html'
})
export class HappenedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
