import { Injectable } from '@angular/core';
import {RequestConfig} from '../configs/request.config';
import {catchError, map} from 'rxjs/operators';
import {ResponseService} from './response.service';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private http: HttpClient,
              private responseService: ResponseService) { }

  host = environment.host;

  setPassword(body) {
    return this.http.post(this.host + '/api/v1/account/updatePassword', body, RequestConfig.options)
      .pipe(map(res => {
        const error = this.responseService.parseError(res);
        if (error) {
          throw new Error(error);
        }

        return this.responseService.parseResponse(res);
      }),
        catchError(err => {
          console.log(err);
          this.responseService.parseUnhandledError(err);
          throw err;
        }));
  }

  updatePassword(body) {
    return this.http.post(this.host + '/api/v1/account/updatePassword', body, RequestConfig.options)
      .pipe(map(res => {
          const error = this.responseService.parseError(res);
          if (error) {
            throw new Error(error);
          }

          return this.responseService.parseResponse(res);
        }),
        catchError(err => {
          console.log(err);
          this.responseService.parseUnhandledError(err);
          throw err;
        }));
  }

  updateEmail(body) {
    return this.http.post(this.host + '/api/v1/account/updateEmail', body, RequestConfig.options)
      .pipe(map(res => {
          const error = this.responseService.parseError(res);
          if (error) {
            throw new Error(error);
          }

          return this.responseService.parseResponse(res);
        }),
        catchError(err => {
          console.log(err);
          this.responseService.parseUnhandledError(err);
          throw err;
        }));
  }
}
