<div class="preloader" [ngClass]="[class, state]" #loader [hidden]="hidden">
  <div [ngClass]="{'preloader__center': !select, 'preloader__right pr-2': select}">
    <div class="spinner-border"
         role="status"
         [ngClass]="'text-' + color"
         [class.spinner-border-sm]="select || sm">
      <span class="sr-only">Загрузка...</span>
    </div>
  </div>
</div>
