import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {

  constructor(private toastr: ToastrService) { }

  parseError(data: any, safeMode?) {
    if (!data.error) {
      return false;
    }

    if (!safeMode || safeMode && data.error.description) {
      // this.toastr.error(data.error.description || 'Sorry, unknown error', 'Error');
    }

    return data.error;
  }

  parseResponse(res: any) {

    if (res.description) {
      // this.toastr.success(res.description, 'Success');
    }

    if (res.hasOwnProperty('data')) {
      return res.data;
    }

    return res;
  }

  parseUnhandledError(err: any) {
    // this.toastr.error(err?.error?.error || 'Sorry, unknown error', 'Error');
  }
}
