import { Injectable } from '@angular/core';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  status: any = {
    warning: 'Warning'
  };

  constructor(private toastr: ToastrService) { }

  incorrectData() {
    this.toastr.warning('Please, enter the correct field', this.status.warning);
  }

  incorrectValue() {
    this.toastr.warning('Please, enter the correct field', this.status.warning);
  }

  passNotMatch() {
    this.toastr.warning('Пароли не совпадают', this.status.warning);
  }
}
